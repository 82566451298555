import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'powerbank-feature-speed',
  templateUrl: './powerbank-feature-speed.component.html',
  styleUrls: ['./powerbank-feature-speed.component.css', "../powerbank-feature-default/powerbank-feature-default.component.css"]
})
export class PowerbankFeatureSpeedComponent implements OnInit {
  @Output() initJoinUI = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

}
