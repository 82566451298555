import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root',
})
export class TempUsersService {
  public static backendURL = environment.baseURL + 'users/tmp';
  public tempUser: string = '';
  constructor(@Inject(HttpClient) private http: HttpClient) {
    const user = localStorage.getItem('sess');
    this.tempUser = user ? user : '';
  }
  setTempUser(userid: string) {
    this.tempUser = userid;
    localStorage.setItem('sess', userid);
  }
  getTempUser() {
    if (!this.tempUser && !localStorage.getItem('sess')) {
      return '';
    } else return this.tempUser || localStorage.getItem('sess');
  }
  async create() {
    return this.http
      .get(TempUsersService.backendURL, { responseType: 'text' })
      .toPromise();
  }
}
