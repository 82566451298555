import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-powerbank-tagline-speed',
  templateUrl: './powerbank-tagline-speed.component.html',
  styleUrls: ['./powerbank-tagline-speed.component.css', "../powerbank-taglines-default/powerbank-taglines-default.component.css"]
})
export class PowerbankTaglineSpeedComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
