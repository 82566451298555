<div class="flex flex-row feature flex-column-reverse-wide flex-align-center
    ">
    <div class="feature-text margin">
        <div class="feature-heading">
            Up to <strong>20Gbps</strong> Data Transfer
        </div>
        <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. A est illum quae officiis, repellendus suscipit
            illo reprehenderit vero cumque molestiae. Delectus?</p>
    </div>
    <div class="feature-img flex flex-justify-center"><img src="assets/images/speed.svg" alt=""></div>

</div>
<div class="flex flex-row feature flex-column-wide flex-align-center
        ">
    <div class="feature-img flex flex-justify-center"><img src="assets/images/speed2.svg" alt=""></div>
    <div class="feature-text margin">
        <div class="feature-heading">
            Recharge <strong>80%</strong> in an <strong>hour</strong>
        </div>
        <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. A est illum quae officiis, repellendus suscipit
            illo reprehenderit vero cumque molestiae. Delectus?</p>
    </div>

</div>
<div class="flex flex-row feature flex-column-reverse-wide flex-align-center
            ">
    <div class="feature-text margin">
        <div class="feature-heading">
            Up to <strong>800W</strong> Power Transfer
        </div>
        <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. A est illum quae officiis, repellendus suscipit
            illo reprehenderit vero cumque molestiae. Delectus?</p>
    </div>
    <div class="feature-img flex flex-justify-center"><img src="assets/images/monitor2.svg" alt=""></div>
</div>
<div class="flex flex-row feature flex-column-wide flex-align-center
            ">
    <div class="feature-img flex flex-justify-center"><img src="assets/images/power.svg" alt=""></div>
    <div class="feature-text margin">
        <div class="feature-heading">
            Up to <strong>300Wh </strong>Capacity
        </div>
        <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. A est illum quae officiis, repellendus suscipit
            illo reprehenderit vero cumque molestiae. Delectus?</p>
    </div>

</div>
<div class="flex flex-row feature flex-column-reverse-wide flex-align-center
                ">
    <div class="feature-text margin">
        <div class="feature-heading">
            Up to <strong>8 USB-C </strong>Capacity
        </div>
        <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. A est illum quae officiis, repellendus suscipit
            illo reprehenderit vero cumque molestiae. Delectus?</p>
    </div>
    <div class="feature-img flex flex-justify-center"><img src="assets/images/devices_tick.svg" alt=""></div>
</div>