<h1>Privacy Policy for InstantPost Printers and Scanners Private Limited</h1>
<h3>Who we are</h3>

Our website address is: http://instantpost.in. At InstantPost, accessible from
https://instantpost.in, one of our main priorities is the privacy of our
visitors. This Privacy Policy document contains types of information that is
collected and recorded by InstantPost and how we use it. If you have additional
questions or require more information about our Privacy Policy, do not hesitate
to contact us by writing to us at privacy&#64;instantpost.in or by calling us at
+91-9916374600. This Privacy Policy applies only to our online activities and is
valid for visitors to our website with regards to the information that they
shared and/or we collect on the website. This policy is not applicable to any
information collected offline or via channels other than this website.

<h3>Consent</h3>

By using our website, you hereby consent to our Privacy Policy and agree to its
terms. For our Terms and Conditions, please visit the Terms and Conditions of
use.

<h3>Information we collect and how we use it</h3>

The personal information that you are asked to provide, and the reasons why you
are asked to provide it, will be made clear to you at the point we ask you to
provide your personal information. If you contact us directly, we may receive
additional information about you such as your name, email address, phone number,
the contents of the message and/or attachments you may send us, and any other
information you may choose to provide. When you register for an Account,
subscribe to our newsletter under "Join the Tribe" campaign, we may ask for your
contact information, including items such as name, company name, address, email
address, and telephone number.

<h4>Comments</h4>

When visitors leave comments on the site we collect the data shown in the
comments form, and also the visitor’s IP address and browser user agent string
to help spam detection.

<h4>Media</h4>

If you upload images to the website, you should avoid uploading images with
embedded location data (EXIF GPS) included. Visitors to the website can download
and extract any location data from images on the website.

<h4>Contact forms</h4>

If you complete and submit any online form on the website then we will collect
the data provided by you including the visitor's IP address and browser user
agent string to help spam detection.

<h4>Cookies and LocalStorage</h4>

If you leave a comment on our site you may opt-in to saving your name, email
address and website in cookies and/or in LocalStorage of the web browser. These
are for your convenience so that you do not have to fill in your details again
when you leave another comment. These cookies and data in LocalStorage will last
for one year. If you visit our login page, we will set a temporary cookie to
determine if your browser accepts cookies and/or store temporary data in
LocalStorage to determine if browser can save data in LocalStorage. This cookie
and/or data in LocalStorage contains no personal data and cookie is discarded
when you close your browser. When you log in, we will also set up several
cookies and/or store data in LocalStorage to save your login information and
your screen display choices. Login cookies, screen options cookies, and/or data
stored in LocalStorage last for a year. If you select "Remember Me", your login
will persist forever. If you log out of your account, the login cookies will be
removed and personal data will be removed from LocalStorage. If you edit or
publish an article, an additional cookie will be saved in your browser and/or
data will be saved in LocalStorage of the browser. This cookie and/or data in
the LocalStorage includes no personal data and simply indicates the post ID of
the article you just created or edited. It does not expire. For more general
information on cookies, please read "What Are Cookies" from Cookie Consent.

<h4>Embedded content from other websites</h4>

Articles on this site may include embedded content (e.g. videos, images,
articles, etc.). Embedded content from other websites behaves in the exact same
way as if the visitor has visited the other website. These websites may collect
data about you, use cookies, embed additional third-party tracking, and monitor
your interaction with that embedded content, including tracking your interaction
with the embedded content if you have an account and are logged in to that
website. We encourage you to read their invidividual privacy policy to
understand how the data stored by them is being used.

<h4>Log Files</h4>

InstantPost follows a standard procedure of using log files. These files log
visitors when they visit websites. All hosting companies do this and a part of
hosting services' analytics. The information collected by log files include
internet protocol (IP) addresses, browser type, Internet Service Provider (ISP),
date and time stamp, referring/exit pages, and possibly the number of clicks.
These are not linked to any information that is personally identifiable. The
purpose of the information is for analyzing trends, administering the site,
tracking users' movement on the website, and gathering demographic information.

<h3>How we use your information</h3>

We use the information we collect in various ways, including to:
<ol>
  <li>Provide, operate, and maintain our website</li>
  <li>Improve, personalize, and expand our website</li>
  <li>Understand and analyze how you use our website</li>
  <li>Develop new products, services, features, and functionality</li>
  <li>
    Communicate with you, either directly or through one of our partners,
    including for customer service, to provide you with updates and other
    information relating to the webste, and for marketing and promotional
    purposes
  </li>
  <li>Send you emails</li>
  <li>Find and prevent fraud</li>
</ol>

<h4>Data sharing</h4>

InstantPost regularly shares data with third party vendors and service providers
on a need to know basis as an aggregate set or individual user information
depending on the requirements to fulfill particular user experience.

<h4>Compulsory dispatch after print</h4>

InstantPost considers printed content requested by user as their private data
that may reflect opinions, choices, biases, orientation which should be kept
confidential unless users choose to make them public. Any print request made by
users, if blocked for printing and/ or printed by InstantPost will be
dispatched, at the earliest possible, to recipient address in order to not hold
any private data of users with InstantPost without any reasonable requirement.
No cancellation request after printing will be entertained.

<h4>Advertising Partners Privacy Policies</h4>

You may consult this list to find the Privacy Policy for each of the advertising
partners of InstantPost. Third-party ad servers or ad networks use technologies
like cookies, JavaScript, or Cookies that are used in their respective
advertisements and links that appear on InstantPost, which are sent directly to
users' browser. They automatically receive your IP address when this occurs.
These technologies are used to measure the effectiveness of their advertising
campaigns and/or to personalize the advertising content that you see on websites
that you visit. Note that InstantPost has no access to or control over these
cookies that are used by third-party advertisers.

<h4>Third Party Privacy Policies</h4>

InstantPost's Privacy Policy does not apply to other advertisers or websites.
Thus, we are advising you to consult the respective Privacy Policies of these
third-party ad servers for more detailed information. It may include their
practices and instructions about how to opt-out of certain options. You can
choose to disable cookies through your individual browser options. To know more
detailed information about cookie management with specific web browsers, it can
be found at the browsers' respective websites.

<h4>Public content on InstantPost</h4>

InstantPost allows users to make their posts public making them freely available
on Internet to anyone and also to other InstantPost users to print them at will.
Once a particular post is made public, although users do have option to make it
private again, limit on its access to anyone cannot be controlled and will not
be responsibility of InstantPost. Users are advised to exercise caution while
making a Post public on InstantPost as they will solely bear all
responsibilities for any losses, financial, physical or emotional, due to such
an exposure in public.

<h3>CCPA Privacy Rights (Do Not Sell My Personal Information)</h3>

Under the CCPA, among other rights, California consumers have the right to:
<ol>
  <li>
    Request that a business that collects a consumer's personal data disclose
    the categories and specific pieces of personal data that a business has
    collected about consumers.
  </li>
  <li>
    Request that a business delete any personal data about the consumer that a
    business has collected.
  </li>
  <li>
    Request that a business that sells a consumer's personal data, not sell the
    consumer's personal data.
  </li>
</ol>

If you make a request, we have one month to respond to you. If you would like to
exercise any of these rights, please contact us at privacy&#64;instantpost.in.

<h3>GDPR Data Protection Rights</h3>

We would like to make sure you are fully aware of all of your data protection
rights. Every user is entitled to the following:
<ol>
  <li>
    The right to access – You have the right to request copies of your personal
    data. We may charge you a small fee for this service.
  </li>
  <li>
    The right to rectification – You have the right to request that we correct
    any information you believe is inaccurate. You also have the right to
    request that we complete the information you believe is incomplete.
  </li>
  <li>
    The right to erasure – You have the right to request that we erase your
    personal data, under certain conditions.
  </li>
  <li>
    The right to restrict processing – You have the right to request that we
    restrict the processing of your personal data, under certain conditions.
  </li>
  <li>
    The right to object to processing – You have the right to object to our
    processing of your personal data, under certain conditions.
  </li>
  <li>
    The right to data portability – You have the right to request that we
    transfer the data that we have collected to another organization, or
    directly to you, under certain conditions.
  </li>
</ol>

If you make a request, we have one month to respond to you. If you would like to
exercise any of these rights, please contact us at privacy&#64;instantpost.in.

<h3>Children's Information</h3>

Another part of our priority is adding protection for children while using the
internet. We encourage parents and guardians to observe, participate in, and/or
monitor and guide their online activity. InstantPost does not knowingly collect
any Personal Identifiable Information from children under the age of 13. If you
think that your child provided this kind of information on our website, we
strongly encourage you to contact us immediately at privacy&#64;instantpost.in
and we will do our best efforts to promptly remove such information from our
records.

<h3>Limited liability</h3>

InstantPost website takes necessary measures, known to InstantPost, to keep your
data secure but does not take any responsibility for data leak due to
circumstances beyond control of InstantPost not limited to sharing password,
natural calamity, strikes, riots, sabotage, fire, theft, war, hardware failure,
software failure, software bugs, government action, insurrection, unauthorized
security breach by an InstantPost employee or outsider on InstantPost properties
both physical and digital or on user’s property both physical and digital not
limited to computer or mobile device. Loss of data also includes events of leak
that may occur with vendors supporting InstantPost as listed in the previous
section, intentionally or unintentionally, being beyond control of InstantPost
authority. InstantPost will not be responsible for any losses, financial, mental
or emotional, incurred by user in event of such data leak.

<p>
  This policy is effective as of Dec 01, 2020 and the policy terms have been
  last updated on Jan 10, 2022.
</p>
