<div class="overlay" #overlay (click)="closeOverlay()">
    <div *ngIf="loadStats" (click)="$event.stopPropagation()" class="stat-dialog has-bg-white">
        <h1 class="text-center">You rock!</h1>
    </div>
    <div (click)="$event.stopPropagation()" class="has-bg-white stripe-form" *ngIf="stripePaymentInit">
        <form class="flex flex-column" ngNativeValidate id="payment-form">
            <h3>Pay only Rs.XXX</h3>
            <div class="flex flex-justify-center stripe-email-input">
                <input id="stripe-payment-email" type="email" class="landing-page-input"
                    placeholder="Your email address" required style="margin-bottom:20px;width:100%">
            </div>
            <div id="card-element">
                <!--Stripe.js injects the Card Element-->
            </div>
            <button class="landing-page-btn" id="submit" [class.no-hover-btn]="stripePayLoading"
                style="margin-top:20px">
                <div *ngIf="stripePayLoading" class="loader">
                </div>
                <span *ngIf="!stripePayLoading" id="button-text">Pay Securely</span>
            </button>
            <p id="card-error" role="alert"></p>
            <p class="result-message hidden">
                Payment succeeded, now go ahead invite others for <b>10% more</b> discount if they book a bank now
            </p>
        </form>
    </div>
</div>
<div class="index">
    <h1 class="text-center">Join The Tribe</h1>
    <div class="flex flex-justify-center"><img style="height:105px" src="assets/images/book.svg" alt=""></div>
    <p class="text-center">We are launching soon reserve a spot by paying Rs.XYZ to book your spot as the production
        quantity is gonna
        be limited </p>
    <div class="flex flex-justify-center">
        <div id="payment-request-button"></div>
        <button class="landing-page-btn" [class.no-hover-btn]="initPayment || hasBooked" (click)="initBooking()">
            <div *ngIf="!initPayment && !hasBooked">
                Book your spot!
            </div>
            <div *ngIf="hasBooked">You've booked a powerbank</div>
            <div class="loader" *ngIf="initPayment">

            </div>
        </button>
    </div>
    <div style="margin-top:10px;margin-bottom:0px" *ngIf="paymentError.length" class="message flex flex-justify-center">
        <div class="failure-message">
            <div class="message-icon">
                <i class="fas fa-times-circle"></i>
            </div>{{paymentError}}
        </div>
    </div>
    <div class="flex flex-justify-center flex-column">
        <h2 class="flex flex-justify-center">Newsletter</h2>
        <!-- <div style="padding:10px" class="text-center flex flex-align-center flex-justify-center invitation-stat"
            *ngIf="invitationStat"><b> You are
                ahead of
                {{invitationStat}}% of users in
                inviting</b> <i title="Know more" class="far fa-question-circle question-button"
                (click)="openStatsUI()"></i>
        </div> -->
        <p *ngIf="!requestError&&!requestMessage" class="text-center" style="padding:17px;margin:0px;padding-top:0px">
            Get notified
            about the next update!</p>
        <div *ngIf="requestError" class="message flex flex-justify-center">
            <div class="failure-message">
                <div class="message-icon">
                    <i class="fas fa-times-circle"></i>
                </div>{{requestErrorMessage}}
            </div>
        </div>
        <form #f="ngForm" class="flex-justify-center" (ngSubmit)="formSubmitHandle(f)" ngNativeValidate>
            <div class="notify-form-elements flex-justify-center flex flex-row" *ngIf="!isSubscribed">
                <input class="landing-page-input no-right-border-radius" type="text" type="email"
                    placeholder="Your email address" name="email" required ngModel #email="ngModel">
                <button style="height:100%;" class="landing-page-btn no-left-border"
                    [class.no-hover-btn]="subscribeButtonLoader || requestMessage.length" type="submit">
                    <div *ngIf="subscribeButtonLoader" class="loader">
                    </div>
                    <div *ngIf="!subscribeButtonLoader" class="">
                        Sign Up
                    </div>
                </button>
            </div>
            <div class="notify-form-elements flex-justify-center flex flex-row" *ngIf="isSubscribed">
                <input type="text" class="landing-page-input no-right-border-radius" placeholder="Your friend's email"
                    name="email" required ngModel #email="ngModel">
                <button style="height:100%;" class="landing-page-btn no-left-border" type="submit"
                    [class.no-hover-btn]="subscribeButtonLoader">
                    <div *ngIf="subscribeButtonLoader" class="loader">
                    </div>
                    <div *ngIf="!subscribeButtonLoader">
                        Invite
                    </div>
                </button>
            </div>
        </form>
        <div class="message" *ngIf="requestMessage.length">
            <div class="success-message flex-justify-center">
                <div class="message-icon">
                    <i class="fas fa-check-circle"></i>
                </div>
                <p style="padding:0px 10px;margin:0px 10px;" class="text-center" [innerHTML]="requestMessage"></p>
            </div>
        </div>
    </div>
</div>