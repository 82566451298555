import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { TempUsersService } from '../shared/temp-user.service';
import { EventLog } from '../shared/classes/event-log.class';
import { EventLogService } from '../shared/event-log.service';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css'],
})
export class IndexComponent implements OnInit {
  public adSource: string = '';
  public isNavOpen: Boolean = false;
  public showConsent: Boolean = true;
  constructor(
    public router: Router,
    private tempUsersService: TempUsersService,
    private eventLogService: EventLogService,
    private activatedRoute: ActivatedRoute
  ) {
    const hasConsent = JSON.parse(localStorage.getItem('event_consent'));
    if (hasConsent !== null) {
      this.showConsent = false;
    }
  }
  ngOnInit() {
    this.router.events.subscribe((evt) => {
      document.querySelector('.content').scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    });
    this.activatedRoute.queryParams.subscribe((params) => {
      this.adSource = params['adsource'] ? params['adsource'] : '';
      if (this.adSource) localStorage.setItem('ad', this.adSource);
      else this.adSource = localStorage.getItem('ad') || '';
    });
    if (!this.tempUsersService.getTempUser()) {
      this.tempUsersService
        .create()
        .then((user) => {
          this.tempUsersService.setTempUser(user);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
  getRoot() {
    return this.router.url.split('?')[0];
  }
  openNavbar() {
    this.isNavOpen = !this.isNavOpen;
  }
  closeConsent() {
    this.showConsent = false;
  }
  declineConsent() {
    let event = new EventLog(
      this.adSource,
      'click',
      'index',
      'events_consent',
      this.tempUsersService.getTempUser(),
      'decline'
    );
    this.eventLogService.add(event, true);
    localStorage.setItem('event_consent', '0');
    this.closeConsent();
  }
  acceptConsent() {
    this.closeConsent();
    let event = new EventLog(
      this.adSource,
      'click',
      'index',
      'events_consent',
      this.tempUsersService.getTempUser(),
      'accept'
    );
    this.eventLogService.add(event, true);
    localStorage.setItem('event_consent', '1');
  }
}
