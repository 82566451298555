import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'powerbank-feature-power',
  templateUrl: './powerbank-feature-power.component.html',
  styleUrls: ['./powerbank-feature-power.component.css', "../powerbank-feature-default/powerbank-feature-default.component.css"]
})
export class PowerbankFeaturePowerComponent implements OnInit {
  @Output() initJoinUI = new EventEmitter();
  public readonly AD_IDENTIFIER = "POWER_AD_CAMPAIGN_"
  constructor() { }

  ngOnInit(): void {
  }

}
