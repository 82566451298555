<h1>Investors</h1>
<h3>Aishwayra Goyal</h3>
<a href="https://www.linkedin.com/in/aishwaryagoyal/">Technical Program Manager, Google</a>
<h3>Anirudh Mangipudi</h3>
<a href="https://www.linkedin.com/in/anirudhmangipudi/">Senior Product Manager, Livspace.com</a>
<h3>Ravitheja Reddy</h3>
<a href="https://www.linkedin.com/in/ravi-theja-reddy-a41a8841/">ASIC Engineer, NVIDIA</a>
<h3>Sachin Srivastava</h3>
<a href="https://www.linkedin.com/in/sachin-srivastava-017a8325/">Data Engineering Manager, United Health Group/Optum</a>
<h3>Shivji Kumar Jha</h3>
<a href="https://www.linkedin.com/in/shivjijha/">Software Engineer, Nutanix</a>
