import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IndexComponent } from './index/index.component';
import { TermsComponent } from './terms/terms.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { InvestorsComponent } from './investors/investors.component';
import { TeamComponent } from './team/team.component';
import { HomeUsbcComponent } from './home-usbc/home-usbc.component';

const routes: Routes = [
  {
    path: '', component: IndexComponent,
    children: [{
      path: 'terms',
      component: TermsComponent
    }, {
      path: 'about',
      component: AboutComponent
    }, {
      path: 'contact',
      component: ContactComponent
    }, {
      path: 'privacy',
      component: PrivacyComponent
    },
    {
      path: 'investors',
      component: InvestorsComponent
    }, {
      path: 'team',
      component: TeamComponent
    }, {
      path: 'usbc',
      component: HomeUsbcComponent
    }]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
