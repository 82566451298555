import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IndexComponent } from './index/index.component';
import { HomePowerbankComponent } from './home-powerbank/home-powerbank.component';
import { HomeUsbcComponent } from './home-usbc/home-usbc.component';
import { PowerbankFeatureDefaultComponent } from './powerbank-feature-default/powerbank-feature-default.component';
import { PowerbankFeaturePowerComponent } from './powerbank-feature-power/powerbank-feature-power.component';
import { PowerbankFeatureSpeedComponent } from './powerbank-feature-speed/powerbank-feature-speed.component';
import { PowerbankTaglinesDefaultComponent } from './powerbank-taglines-default/powerbank-taglines-default.component';
import { PowerbankTaglineSpeedComponent } from './powerbank-tagline-speed/powerbank-tagline-speed.component';
import { TermsComponent } from './terms/terms.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { InvestorsComponent } from './investors/investors.component';
import { TeamComponent } from './team/team.component';
import { PowerbankJoinUsComponent } from './powerbank-join-us/powerbank-join-us.component';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [
    AppComponent,
    IndexComponent,
    HomePowerbankComponent,
    HomeUsbcComponent,
    PowerbankFeatureDefaultComponent,
    PowerbankFeaturePowerComponent,
    PowerbankFeatureSpeedComponent,
    PowerbankTaglinesDefaultComponent,
    PowerbankTaglineSpeedComponent,
    TermsComponent,
    AboutComponent,
    ContactComponent,
    PrivacyComponent,
    InvestorsComponent,
    TeamComponent,
    PowerbankJoinUsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
