<h1>InstantPost Printers and Scanners Private Limited Terms and Conditions of Service</h1>
<h3>1. Terms</h3>

By accessing the website at https://instantpost.in, you are agreeing to be bound by these terms and conditions of service, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms and conditions, you are prohibited from using or accessing this site. The materials contained in this website are protected by applicable copyright and trademark law.

<h3>2. Use License</h3>

Permission is granted to temporarily download one copy of the materials (information or software) on InstantPost Printers and Scanners Private Limited's website for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:
    <ol type="i">
        <li>modify or copy the materials;</li>
        <li>use the materials for any commercial purpose, or for any public display (commercial or non-commercial);</li>
        <li>attempt to decompile or reverse engineer any software contained on InstantPost Printers and Scanners Private Limited's website;</li>
        <li>remove any copyright or other proprietary notations from the materials; or</li>
        <li>transfer the materials to another person or "mirror" the materials on any other server.</li>
    </ol>
This license shall automatically terminate if you violate any of these restrictions and may be terminated by InstantPost Printers and Scanners Private Limited at any time. Upon terminating your viewing of these materials or upon the termination of this license, you must destroy any downloaded materials in your possession whether in electronic or printed format.

<h3>3. Disclaimer of warranty</h3>
The InstantPost offerings including but not limited to website, platform, printing, advertising, delivery and any part of it are provided on an ‘as is’ and ‘as available’ basis. To the fullest extent permitted under applicable law, InstantPost explicitly disclaims all representations, liabilities and warranties of any kind with respect to the website, platform, printing, advertising, delivery, user generated content as Posts and any products and/or services, any part of it, available on or through InstantPost website, whether, expressed or implied, including, but not limited to, any and all warranties:
    <ol type="a">
        <li>Of merchantability or fitness for a particular purpose.</li>
        <li>Of non-infringement and title.</li>
        <li>That any use of the site will be free from any interruptions, delays, inaccuracies, server down-time, errors or omissions.</li>
        <li>That the site will be secure, or free of viruses, worms or other harmful components.</li>
        <li>That the site and/or any part of it will satisfy your requirements or expectations.</li>
        <li>That information, data or materials presented or displayed on this site are correct, accurate or reliable.</li>
        <li>InstantPost as the service provider shall be under no liability whatsoever in respect of any loss or damage arising directly or indirectly out of the decline of authorization for any Financial Transaction, on Account of the Cardholder having exceeded the preset limit mutually agreed by us with our acquiring bank from time to time.</li>
    </ol>

<h3>4. Limitations</h3>

In no event shall InstantPost Printers and Scanners Private Limited or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on InstantPost Printers and Scanners Private Limited's website, even if InstantPost Printers and Scanners Private Limited or a InstantPost Printers and Scanners Private Limited authorized representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.

InstantPost and its affiliates, shareholders, directors, officers, employees will not be liable (jointly or severally) to you or any other person as a result of your access or use of the services for indirect, consequential, special, incidental, punitive, or exemplary damages, including, without limitation, lost profits, lost savings and lost revenues called collectively, the ‘Damages’, whether or not characterized in negligence or other theory of liability, even if InstantPost has been advised about the possibility of or could have foreseen any of the Damages, and irrespective of any failure of an essential purpose of a limited remedy. If any applicable authority holds any portion of this section to be unenforceable, then the InstantPost liability will be limited to the fullest possible extent permitted by applicable law.

THIS SITE MAY CONTAIN TECHNICAL OR OTHER MISTAKES, INACCURACIES OR TYPOGRAPHICAL ERRORS WHICH MAY OR MAY NOT HAVE BEEN KNOWN OR ADVISED TO INSTANTPOST. INSTANTPOST RESERVES ALL RIGHTS TO MAKE CHANGES TO THE CONTENT AND SERVICES OFFERED ON THIS SITE, INCLUDING THE PRICES AND DESCRIPTIONS OF ANY SERVICES OR PRODUCTS LISTED HEREIN, AT ANY TIME WITHOUT NOTICE. THE MATERIALS OR SERVICES ON INSTANTPOST WEBSITE MAY BE OUT OF DATE, AND INSTANTPOST MAKES NO COMMITMENT TO UPDATE SUCH MATERIALS OR SERVICES. THE USE OF INSTANTPOST SERVICES THROUGH THE INSTANTPOST WEBSITE OR ANY OTHER CHANNEL OF COMMUNICATION SHOULD BE DONE AT YOUR OWN RISK AND FURTHER ACCESS ON INSTANTPOST WEBSITE AND/OR ITS CONTENT IMPLIES AGREEMENT THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE NOT LIMITED TO YOU, YOUR COMPUTER SYSTEM, YOUR DATA THAT RESULTS FROM SUCH ACTIVITIES.

Please read these terms of use carefully along with privacy policy in order to understand legal implications and limitations of using InstantPost.

<h3>5. Accuracy of materials</h3>

The materials appearing on InstantPost Printers and Scanners Private Limited's website could include technical, typographical, or photographic errors. InstantPost Printers and Scanners Private Limited does not warrant that any of the materials on its website are accurate, complete or current. InstantPost Printers and Scanners Private Limited may make changes to the materials contained on its website at any time without notice. However InstantPost Printers and Scanners Private Limited does not make any commitment to update the materials.

<h3>6. Links</h3>

InstantPost Printers and Scanners Private Limited has not reviewed all of the sites linked to its website and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by InstantPost Printers and Scanners Private Limited of the site. Use of any such linked website is at the user's own risk.

<h3>7. Intellectual property information</h3>

InstantPost holds exclusive rights on specific content on InstantPost website required for offering and rendering our services to users. InstantPost website also makes use of multiple software and hardware products for which copyrights are held with respective manufacturers and service providers.

USER GENERATED CONTENT: Any content that is uploaded by users in forms not limited to photos, graphics, images, documents, books, manuscripts, articles, forms, magazines collectively named as “Post” on the InstantPost website is under complete ownership of respective users who have uploaded the content. InstantPost does not claim any rights on user generated content and also does not take any responsibility or liability for any kind of damages that may occur not limited to, financial, physical or emotional, to any individual, group, organization either intentionally or unintentionally irrespective of the fact if InstantPost is aware of or has been advised about such user generated content or post.

<h3>8. Indemnification</h3>

Upon a request by us, you agree to defend, indemnify, and hold InstantPost and its shareholders, directors and employees harmless from all liabilities, claims, and expenses, including lawyer’s fees, that arise from your use or misuse of this site. We reserve the right, at our own expense, to assume the exclusive defence and control of any matter otherwise subject to indemnification by you, in which event you will cooperate with us in asserting any available defences.

<h3>9. Code of conduct</h3>

Your use of InstantPost website is subject to all applicable laws and regulations, and you are solely responsible for the substance of your communications through the site. By posting information in or otherwise using any communications service that may be available to you on or through InstantPost website, you agree that you will not upload, share, post, or otherwise distribute or facilitate distribution of any content, not limited to text, communications, software, images, sounds, data, documents, books, magazines, forms or other information, that:
    <ol type="a">
        <li>Is unlawful, threatening, abusive, harassing, defamatory, libellous, deceptive, fraudulent, invasive of another’s privacy, contains explicit or graphic descriptions or accounts of sexual acts (including but not limited to sexual language of a violent or threatening nature directed at another individual or group of individuals), or otherwise violates our rules or policies.</li>
        <li>Victimises, harasses, degrades, or intimidates an individual or group of individuals on the basis of religion, gender, sexual orientation, race, ethnicity, age, or disability.</li>
        <li>Infringes on another’s patent, trademark, trade secret, copyright, or other proprietary right.</li>
        <li>Constitutes unauthorized or unsolicited advertising, junk or bulk email (also known as “spamming”), chain letters, any other form of unauthorized solicitation, or any form of lottery or gambling.</li>
        <li>Contains software viruses or any other computer code, files, or programs that are designed or intended to disrupt, damage, or limit the functioning of any software, hardware, or telecommunications equipment or to damage or obtain unauthorized access to any data or other information of any third party.</li>
        <li>Impersonates any person or entity, including any of our employees or representatives.</li>
    </ol>
InstantPost does not screen, pre-screen, monitor, or edit the content posted by users of InstantPost website that may be available on or through this site. However, InstantPost reserves all rights, at their sole discretion, to remove any content that does not comply with these Terms of Use and any other rules of user conduct for our site, or is otherwise harmful, objectionable, or inaccurate as per judgement from InstantPost which will be final and binding on all users. We are not responsible for any failure or delay in removing such content. You hereby consent to such removal and waive any claim against us arising out of such removal of content.

You are permitted to make use of InstantPost website to the extend of enjoying the offered services and not to misuse the resources available in a way that it impacts negatively not limited to operations of the InstantPost website, experience of other users on InstantPost website, security, network usage, server performance, software performance. You agree that InstantPost may at any time, and at its sole discretion, terminate your membership, account, or other affiliation with InstantPost website without prior notice to you for violating any of the above provisions that may result in misuse of the InstantPost website. In addition, you agree that we will cooperate fully with investigations of violations of systems or network security at InstantPost website, including cooperating with law enforcement authorities in investigating suspected criminal violations.

<h3>10. Limitation on availability</h3>

Although InstantPost website may be accessible worldwide, we make no representation that materials on InstantPost website are appropriate or available for use in locations outside India, and accessing them from territories where InstantPost owned content and/ or user generated contents are illegal is prohibited. Those who choose to access this site from other locations do so on their own initiative and are responsible for compliance with local laws. Any offer for any product, service, and/or information made in connection with this site is void where prohibited.

<h3>11. Limitation on printing</h3>

We provide printing facility to everyone neutrally without any reservations not limited to any individuals, group, organizations as permissible under applicable laws. However, InstantPost reserves all rights to justly enforce Fair Use Policy (FUP) in order to assure that both free and paid services availed by users on InstantPost website or on any other channel are not misused for purposes that may harm anyone or negatively impact the experience of users, not limited to InstantPost systems, processes, infrastructure, security on InstantPost platform and otherwise. InstantPost further prohibits users to perform any, partly or severally or all of the following actions:
    <ol type="a">
        <li>Do not place print request for content not permissible under law to be printed as original or as copy of an original. InstantPost uses automated systems that do not allow manual intervention to judge content before printing and it should not be assumed responsibility of InstantPost or any of its employees, shareholders, directors, affiliates or third party vendors.</li>
        <li>Do not place print request of content for which you may, knowingly or unknowingly, infringe anyone’s intellectual property irrespective of the fact InstantPost is aware of it or has been advised about it. User is required, at all times, to know applicable laws related to intellectual property rights in relation to the content requested for print and respective user will be solely and only responsible for any dispute arising from upload and printing of the content.</li>
        <li>Do not place print request for published content, produced by the user, but restricted by the publishing contract to be printed without consent of the publisher. InstantPost uses automated systems that do not allow manual intervention to judge content before printing and it should not be assumed responsibility of InstantPost or any of its employees, shareholders, directors, affiliates or third party vendors. The users of InstantPost Printing Services through website and any other channels should verify with their publisher if they are allowed to print copies of their published work independently through a third party printing service provider such as InstantPost. Any print request from user will be assumed to free of any such restrictions and will be provided as a regular printing service without any verification from InstantPost. Users of InstantPost Printing Services are solely responsible to make sure they are aware of applicable restrictions and have taken required approvals to take the print of such published work. InstantPost takes no responsibility or is liable to pay for the damages arising from such a print request from user.</li>
        <li>Do not place free print request for content that can be considered promotional campaign not limited to advertisements for which user should make compulsory payments by making request for placement of advertisements termed and used interchangeably as “Ad”, “Ads”, “Ad Page” and/ or “Ad Campaign” on InstantPost website. Any measure and decision taken by InstantPost, to judge user generated content as promotional and/ or advertisement, will final and binding.</li>
        <li>InstantPost does not enforce any hard limits on free printing, but users are expected to confirm delivery by providing us the One Time Password (OTP) printed behind the user content. In case user fails to confirm delivery using InstantPost website then InstantPost reserves all rights to contain, throttle or restrict particular user account usage without notice.</li>
        <li>InstantPost does not restrict sharing content printed free using any of the InstantPost offerings through a legitimate channel. You are absolutely free to share the printed content as applicable and allowed under local and/ or central laws. InstantPost does not take any responsibility on making users aware of laws that restrict sharing content. However, InstantPost restricts you from making copies, re-printing, re-publishing digitally scanning and reproducing in digital format any of the prints requested free of cost from InstantPost.</li>
        <li>Printed content obtained free of any cost from InstantPost, if shared further by user, does not allow anyone including the user to extract any kind of payments from the new receiver of the content for any reason, however legitimate or genuine, including but not limited to delivery, convenience, printing or postage.</li>
        <li>Advertisements printed along free prints may reflect personal choices, opinions, preferences of the user requesting the print. InstantPost does not take any responsibility for any kind of damages, be it financial, physical or emotional, faced by users due to sharing of free printed content and thereby allowing others to view advertisements printed behind their prints.</li>
    </ol>

<h3>12. Limitation on user account</h3>
    <ol type="a">
        <li>Users must not create more than one account on InstantPost platform using InstantPost website or any other communication channel. Printed content obtained free of any cost from InstantPost, if shared further by user, does not allow anyone including the user to extract any kind of payments from the new receiver of the content for any reason, however legitimate or genuine, including but not limited to delivery, convenience, printing or postage.</li>
        <li>User must maintain correct and updated profile and contact data in their user account which reflects their true identity. InstantPost may restrict access without notice to accounts with fake data or data that is not under rightful claim of the user holding the account. Decision of restricting access to a particular account by InstantPost will be final and binding.</li>
        <li>User must not share account password with anyone under any circumstances. InstantPost or any of InstantPost employees, directors or shareholders will never ask for user’s account password.</li>
        <li>User must not allow anyone to view the private sections of InstantPost website that are otherwise not visible without login using correct username and password for the account. Private sections on InstantPost website contain confidential information that InstantPost shares only with user and it should never be shared.</li>
        <li>User must not create fake account for purposes not limited to impersonation, illegal use, defamation, circumventing restrictions on another account of same user, overloading InstantPost systems and processes.</li>
        <li>Not complying with any, partly, severally or all of the above mentioned limitations on handling of user accounts may attract civil or criminal liability on the user.</li>
    </ol>

<h3>13. Eligible cases for payments</h3>
InstantPost will collect payment applicable only in following cases:
<h4>Goods</h4>
User selects to pre-book or purchase merchandise list below:
    <ol type="a">
        <li>Power Banks</li>
        <li>Chargers</li>
        <li>USB Type C Cables</li>
        <li>Conveters Cables</li>
        <li>AutoMagic Smart kiosk or any of it's components</li>
        <li>Disco Dancer 3D Printer or any of it's components</li>
    </ol>

<h4>Services</h4>
    <ol type="a">
        <li>Mechancial Design Services</li>
        <li>3D Printnig Services</li>
        <li>Electronics Design Services</li>
        <li>Software Design Services</li>
    </ol>

<h4>Printing Services</h4>
    <ol type="a">
        <li>User selects to receive a print without ads.</li>
        <li>User selects a print size not available as free print with ads.</li>
        <li>User selects a delivery option not available free with print.</li>
        <li>User selects to deliver to recipient not registered with InstantPost referred as “Friend off InstantPost” on website.</li>
        <li>User selects to display ads behind prints taken by other users.</li>
    </ol>

Payments made to InstantPost through legitimate channels on InstantPost website in any of the above mentioned cases will be eligible for refund, if applicable. This section forms only the eligibility criteria for refund and does not necessarily entitle user for refund. Payments made in any other situation even through legitimate channels will not be eligible for refund. In case you are asked to make payment out of turn please contact InstantPost immediately to report the issue.

<h3>14. Qualifying cases for refund</h3>

InstantPost will provide refund to user only in following circumstances:
<h4>Goods and Services:</h4>
    <ol type="a">
        <li>User has made authentic payment which has been duly received by InstantPost and the goods and/or services ordered has not been delivered to the user within intimated duration from date on which user requested the goods and/or services on InstantPost website.</li>
        <li>User has made authentic payment which has been duly received by InstantPost and the goods received are visibly damaged. This claim for refund is applicable up to 3 days after delivery only when legitimate proof of damage has been provided by user and accepted by InstantPost.</li>
        <li>User has made authentic payment which has been duly received by InstantPost and then cancels the order request before it is processed for packaging and/or delivery.</li>
    </ol>

Under any circumstances, for an eligible case of refund, if user has made the payment using “InstantPost points” termed as “point” or “points” on the InstantPost website, then the applicable refund will also be in “InstantPost points”, irrespective of the claims made by the user to qualify for the refunds.

<h4>Ads:</h4>
    <ol type="a">
        <li>User has made authentic payment which has been duly received by InstantPost and the advert fails to qualify for quality standards during the review process. If re-submission in not made within 30 days from the date of rejection, InstantPost reserves right to cancel the ad request and issue refund.</li>
    </ol>

<h3>15. Non-qualifying cases for refund</h3>
<h4>Prints:</h4>
    <ol type="a">
        <li>No refunds will be provided on basis of quality expectation of user not limited to print size, print quality, ink quality, print method, paper quality, packaging quality, delivery method. User may take the free print first in order to understand quality of final output before placing request for paid print and making payment for it.</li>
        <li>In case the requested paid order has been processed for packaging and/or delivery then no refunds will be applicable on such order request unless it qualifies with point 1 and 2 in the “Goods and Services” section of “Qualifying cases for refund”.</li>
        <li>In case the requested paid print has been blocked for printing and/or printed then no refunds will be applicable on such print request unless it qualifies with point 1 and 2 in the “Prints” section of “qualifying cases for refund”. Print in such case will be delivered to recipient address in order to adhere with privacy policy.</li>
        <li>InstantPost does not take responsibility of accuracy of address and mobile number, collectively termed as “contact” or “contacts” on InstantPost website. Any cases of refund arising due to inaccurate contact data provided by user for delivery of goods and/or services will not be considered as a valid case of refund and such cases will be immediately disposed off as null and void.</li>
    </ol>

<h4>Ads:</h4>
    <ol type="a">
        <li>If reviewed and approved by InstantPost, no refunds will be applicable on user request to place ads behind prints of other users on InstantPost.</li>
    </ol>

<h3>16. Modifications</h3>

InstantPost Printers and Scanners Private Limited may revise these terms of service for its website at any time without notice. By using this website you are agreeing to be bound by the then current version of these terms of service.

<h3>17. Governing Law</h3>

These terms and conditions are governed by and construed in accordance with the laws of Lucknow, Uttar Praedsh, India and you irrevocably submit to the exclusive jurisdiction of the courts in Lucknow, Uttar Pradesh, India.

<p>These terms and conditions of service are effective as of Dec 01, 2020 and last updated on Jan 05, 2022.</p>
