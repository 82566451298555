import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

document.write(`<script src="${environment.iplibURL}"> </script>`);
if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then((_) => {
    if ('serviceWorker' in navigator) {
      window.addEventListener('load', async () => {
        // const register = await navigator.serviceWorker.register("/sw.js", { scope: "/" })
        // let subscription = await register.pushManager.getSubscription()
        // if (subscription) return;
        // subscription = await register.pushManager.subscribe({
        //   userVisibleOnly: true,
        //   applicationServerKey: urlBase64ToUint8Array(publicVapidKey)
        // })
        navigator.serviceWorker
          .register('./sw.js')
          .then((registration) => {
            console.log(
              `Service Worker registered! Scope: ${registration.scope}`
            );
            if ('Notification' in window) {
              if (Notification.permission == 'granted') return;
              Notification.requestPermission((result) => {
                console.log(result);
                if (result == 'granted') {
                  new Notification('InstantPost', {
                    body: 'You just enabled web push notifications!',
                    icon: '/assets/images/logo.png',
                  });
                }
              });
            }
          })
          .catch((err) => {
            console.log(`Service Worker registration failed: ${err}`);
          });
      });
    }
  })
  .catch((err) => console.error(err));
