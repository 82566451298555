import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  x;
  public backendURL = environment.baseURL + 'payments/';
  constructor(private http: HttpClient) {}
  getOrder() {
    // let headers = new HttpHeaders({
    //   'Content-Type': 'application/json'
    // });
    return this.http.get(this.backendURL + 'order/razorpay');
  }
  getStripeOrder(isPaymentUSBased) {
    return this.http.get(
      this.backendURL + `order/stripe${isPaymentUSBased ? '?loc=US' : ''}`
    );
  }
  verifyPayment(data) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post(this.backendURL + 'order/razorpay/verify', data, {
      headers: headers,
    });
  }
}
