export class Subscription {
    time: string;
    email: string;
    referrer: string;
    source: string;
    click_source: string;
    user: string;
    adsource: string;
    constructor(email: string, referrer: string = "", source: string = "", click_source: string = "", user: string = "", adsource = "") {
        this.time = new Date().toISOString();
        this.email = email;
        this.referrer = referrer;
        this.source = source;
        this.click_source = click_source;
        this.user = user;
        this.adsource = adsource;
    }
}