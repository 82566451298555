import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { NewsletterService } from '../shared/newsletter.service';
import { EventLogService } from '../shared/event-log.service';
import { EventLog } from '../shared/classes/event-log.class';
import { PaymentService } from '../shared/payment.service';
import { environment } from '../../environments/environment';

import { NgForm } from '@angular/forms';
import { Subscription } from '../shared/classes/newsletter-subscription.class';
import { TempUsersService } from '../shared/temp-user.service';
declare const Razorpay: any;
declare const Stripe: any;
@Component({
  selector: 'powerbank-join-us',
  templateUrl: './powerbank-join-us.component.html',
  styleUrls: [
    './powerbank-join-us.component.css',
    '../index/index.component.css',
  ],
})

// class Subscription {
//   email: string;
//   referrer: string;
//   internal_click_source: string;
//   source: string;
// };
export class PowerbankJoinUsComponent implements OnInit {
  @ViewChild('overlay', { static: false })
  public overlay: ElementRef;
  @Input() source: string;
  @Input() isSubscribed: Boolean;
  @Input() subscribedUser: string;
  @Input() hasBooked: Boolean;
  @Input() adSource: string;
  @Output() isSubscribedChange = new EventEmitter<Boolean>();
  @Output() subscribedUserChange = new EventEmitter<string>();
  @Output() hasBookedChange = new EventEmitter<Boolean>();
  public requestMessage = '';
  public requestErrorMessage = '';
  public initPayment = false;
  public paymentError = '';
  public requestError = false;
  public subscribeButtonLoader = false;
  public hasInvited = false;
  public readonly razorpay_api_id = environment.razorpay_key;
  public readonly isSubscribedMessage =
    'Get <b> additional 10% discount per person </b> you invite  if they reserve a powerbank now!';
  public readonly hasSignedupMessage =
    'Your request for trial is being reviewed, we will reach out to you soon.';
  public readonly hasInvitedMessage =
    "Your friend has been invited, <br class='conditional-break'><b>invite more friends now</b>";
  public invitationStat: number = 0;
  public stripePaymentInit: Boolean = false;
  public loadStats: Boolean = false;
  public stripePayLoading: Boolean = false;
  constructor(
    private newsletterService: NewsletterService,
    private paymentsService: PaymentService,
    private eventLogService: EventLogService,
    private tempUserService: TempUsersService
  ) {}

  ngOnInit() {
    let event = new EventLog(
      this.adSource,
      'click',
      'home-join-us-ui',
      this.source,
      this.tempUserService.getTempUser()
    );
    this.eventLogService.add(event);
    if (this.isSubscribed) this.requestMessage = this.isSubscribedMessage;
    this.invitationStat = JSON.parse(localStorage.getItem('inviteStat')) || 0;
  }
  ngOnDestroy() {
    let event = new EventLog(
      this.adSource,
      'click',
      'home-join-us-ui',
      this.source,
      this.tempUserService.getTempUser(),
      'close'
    );
    this.eventLogService.add(event);
  }
  payWithStripe() {
    const isPaymentUSBased: Boolean = true;
    this.enableOverlay();
    this.stripePaymentInit = true;
    this.stripePayLoading = true;
    this.paymentsService.getStripeOrder(isPaymentUSBased).subscribe(
      (order) => {
        const errorMsg = document.querySelector('#card-error');
        const payBtn = document.getElementById('submit') as HTMLButtonElement;
        const emailInput = document.getElementById(
          'stripe-payment-email'
        ) as HTMLInputElement;
        if (this.isSubscribed) emailInput.value = this.subscribedUser;
        errorMsg.textContent = '';
        console.log(order);
        const stripe = Stripe(
          isPaymentUSBased ? environment.stripe_us_key : environment.stripe_key
        );
        const elements = stripe.elements();
        const card = elements.create('card');
        card.mount('#card-element');
        this.stripePayLoading = false;
        card.on('change', (event) => {
          console.log(event);
        });
        document
          .getElementById('payment-form')
          .addEventListener('submit', async (event) => {
            event.preventDefault();
            this.stripePayLoading = true;
            const result = await stripe.confirmCardPayment(
              order['clientSecret'],
              {
                payment_method: {
                  card: card,
                },
                receipt_email: emailInput.value,
              }
            );
            this.stripePayLoading = false;
            console.log(result);
            if (result.error) {
              errorMsg.textContent = result.error.message;
              this.bookingCallback(emailInput.value, false);
            } else {
              document
                .querySelector('.result-message')
                .classList.remove('hidden');
              this.bookingCallback(emailInput.value, true);
            }
          });
      },
      (err) => console.log(err)
    );
  }
  payWithRazorpay() {
    this.paymentError = '';
    this.initPayment = true;
    this.paymentsService.getOrder().subscribe(
      (order) => {
        console.log(order);
        var options = {
          key: this.razorpay_api_id,
          amount: order['amount'],
          currency: order['currency'],
          description: 'Reserve your spot for the next gen powerbank!',
          order_id: order['id'],
          handler: (response) => {
            console.log(response);
            response.time = new Date().toISOString();
            response.user = this.tempUserService.getTempUser();
            this.paymentsService.verifyPayment(response).subscribe(
              (response) => {
                this.bookingCallback(response['data'].email, true, true);
              },
              (err) => {
                console.log(err);
                this.initPayment = false;
              },
              () => {
                console.log('inner callback');
                this.initPayment = false;
              }
            );
          },
          notes: {
            details: 'test stuff',
          },
          theme: {
            color: '#157065',
          },
          modal: {
            ondismiss: () => {
              this.initPayment = false;
              this.paymentError = 'Transaction cancelled';
              let event = new EventLog(
                this.adSource,
                'transaction_cancelled',
                'home-join-us-ui',
                this.source,
                this.tempUserService.getTempUser(),
                'close'
              );
              this.eventLogService.add(event);
            },
          },
        };
        var rzp1 = new Razorpay(options);
        rzp1.on('payment.failed', (response) => {
          console.log(response);
          this.paymentError = response.error.description;
        });
        rzp1.open();
      },
      (err) => {
        this.initPayment = false;
      },
      () => {
        console.log('outer callback');
      }
    );
  }
  bookingCallback(email, isPaymentSuccessfull, hasStoredEmail = false) {
    const saveSubscriptionData = () => {
      this.subscribedUser = email;
      this.isSubscribed = true;
      this.isSubscribedChange.emit(this.isSubscribed);
      localStorage.setItem('subscribed_user', email);
      localStorage.setItem('subscribed', '1');
      this.requestMessage = this.isSubscribedMessage;
    };
    if (isPaymentSuccessfull) {
      this.hasBooked = true;
      this.hasBookedChange.emit(this.hasBooked);
      localStorage.setItem('booked', '1');
    }
    if (this.isSubscribed) return;
    if (hasStoredEmail) {
      saveSubscriptionData();
      return;
    }
    let subscription = new Subscription(
      email,
      document.referrer,
      this.subscribedUser,
      this.source,
      this.tempUserService.getTempUser(),
      this.adSource
    );
    this.newsletterService.add(subscription).subscribe(
      (data) => {
        saveSubscriptionData();
      },
      (err) => {
        console.log(err);
      }
    );
    console.log(email, isPaymentSuccessfull);
  }
  initBooking() {
    this.payWithStripe();
    // this.payWithRazorpay();
  }
  resetOutputs() {
    this.requestError = false;
    this.requestErrorMessage = '';
    this.requestMessage = '';
  }
  formSubmitHandle(form: NgForm) {
    const email = form.value.email;
    if (email == this.subscribedUser) return;
    this.subscribeButtonLoader = true;
    let subscription = new Subscription(
      email,
      document.referrer,
      this.subscribedUser,
      this.source,
      this.tempUserService.getTempUser(),
      this.adSource
    );
    this.newsletterService.add(subscription).subscribe(
      (data) => {
        this.resetOutputs();
        this.invitationStat = data['invitationRank'];
        localStorage.setItem('inviteStat', String(this.invitationStat));
        this.subscribeButtonLoader = false;
        if (!this.isSubscribed) {
          this.subscribedUser = email;
          localStorage.setItem('subscribed_user', email);
          localStorage.setItem('subscribed', '1');
          this.isSubscribed = true;
          this.requestMessage = this.isSubscribedMessage;
          this.isSubscribedChange.emit(this.isSubscribed);
        } else {
          this.hasInvited = true;
          this.requestMessage = this.hasInvitedMessage;
        }
        console.log(data);
      },
      async (error) => {
        this.resetOutputs();
        this.subscribeButtonLoader = false;
        if (error.status === 0) {
          try {
            await this.newsletterService.addOffline(subscription);
            const oldState = this.isSubscribed;
            navigator.serviceWorker.onmessage = (event) => {
              console.log(event);
              if (event.data && event.data.type == 'SYNCED_SUBSCRIBERS') {
                this.requestMessage = oldState
                  ? this.isSubscribedMessage
                  : this.hasInvitedMessage;
              }
            };
            this.requestMessage =
              'No internet, will be sent to the server when you reconnect, meanwhile continue adding your friends for more discount!';
            console.log(this);
            if (!this.isSubscribed) {
              this.subscribedUser = email;
              localStorage.setItem('subscribed_user', email);
              localStorage.setItem('subscribed', '1');
              this.isSubscribed = true;
              this.isSubscribedChange.emit(this.isSubscribed);
            }
            return;
          } catch (e) {
            console.log(e);
          }
        }
        console.log(error);
        this.requestError = true;
        this.requestErrorMessage =
          'Your request failed, please try after some time';
      }
    );
  }
  enableOverlay() {
    this.overlay.nativeElement.style.display = 'flex';
  }
  closeOverlay() {
    this.overlay.nativeElement.style.display = 'none';
    this.loadStats = false;
  }
  openStatsUI() {
    console.log('hi');
    this.enableOverlay();
    this.loadStats = true;
  }
}
