<div class="feature">
    <div class="feature-text">
        <div class="feature-heading">
            <strong>Printing ATM</strong>
        </div>
        <p>Smart Printing ATM for automating retail printing stores with a contactless 24x7 self-checkout experience.</p>
        <p><strong>Top 30</strong> in Swadeshi Microprocessor Challenge 2020 organised by CDAC and Meity, GoI. Our Smart Printing ATM is compatible with Swadeshi Vega Processor.</p>
        <p><strong>Media Coverage</strong> by YourStory in Feb 2022</p>
    </div>
    <div class="feature-img-kiosk">
        <img src="assets/images/kiosk-right-portrait.jpg" alt="">
    </div>
</div>
<div class="feature">
    <div class="feature-text">
        <!-- div class="feature-heading">
            A <strong>Nano Factory</strong>
        </div>
        <p>Nano Factory is the Most Compact Solution to Manufacturing with it’s Innovative 3D Printing. It fully automates manufacturing with 3D printing and has a highly Scalable and Modular Infrastructure that can be installed anywhere with its unique way of Standardization.</p -->
        <p><strong>Top 5</strong> in Grand Challenge 2021 <!-- for 3D Printing --> organised by CMET, Pune and Meity, GoI</p>
    </div>
    <!-- div class="feature-img-nano">
        <img src="assets/images/disco-dancer-2-sq.gif" alt="">
    </div -->
</div>
<div class="feature">
    <div class="feature-text">
        <!-- div class="feature-heading">
            <strong>Epic</strong> Power Bank
        </div>
        <p>Epic Power Bank is the most feature-rich power bank designed for those to whom 24 hours is not enough. It comes with an unmatched Unlimited Power experience with Extra Charged Swappable Cell Packs and Personalized Ports to power all the gears at once!</p -->
        <p><strong>Winner</strong> in Grand Challenge 2021 <!-- for Power Banks --> organised by CDAC, Meity, GoI and Govt of Uttar Pradesh</p>
    </div>
    <!-- div class="feature-img-epb">
        <img src="assets/images/epb-min.png" alt="">
    </div -->
</div>
<div class="feature">
    <div class="feature-text">
        <!-- div class="feature-heading">
            <strong>WebEye</strong> Fleet Manager
        </div>
        <p>WebEye is the Fleet Management software that enables comprehensive fleet availability monitoring for AutoMagic Smart Kiosks across retail stores to ensure retail store owners and shoppers can have equal access to information and functionality regardless of their location to determine if orders can be fulfilled for customers ariving at any kiosks across retail stores.</p -->
        <p><strong>Top 5</strong> in Innovation Challenge 2021 organised by NIC and Meity, GoI</p>
    </div>
    <!-- div class="feature-img-fleet">
        <img src="assets/images/fleet-manager.png" alt="">
    </div -->
</div>
