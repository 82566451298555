import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { EventLog } from '../shared/classes/event-log.class';
import { SYNC_DB_NAME } from '../../sw/local_storage_config';
@Injectable({
  providedIn: 'root',
})
export class EventLogService {
  public static backendURL = environment.baseURL + 'events/logs';
  constructor(private http: HttpClient) {}
  async add(event: EventLog, forceSend: Boolean = false) {
    const hasConsent = JSON.parse(localStorage.getItem('event_consent'));
    if (!forceSend && hasConsent !== 1) return;
    console.log('here');
    try {
      if ('serviceWorker' in navigator) {
        const regs = await navigator.serviceWorker.getRegistrations();
        if (regs.length == 0) throw new Error('NO_SW');
        let registration = await navigator.serviceWorker.ready;
        // @ts-ignore
        return registration.sync.register('send-eventlogs');
      } else throw new Error('NO_SW');
    } catch (err) {
      console.log(err);
      let headers = new HttpHeaders({
        'Content-Type': 'application/json',
      });
      return this.http
        .post(EventLogService.backendURL, [event], { headers: headers })
        .toPromise();
    }
  }
}
