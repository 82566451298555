<h1>Team</h1>
<h3>Akhil Mohan</h3>
<a href="https://www.linkedin.com/in/i01000001/">Founder & Software Engineer</a>
<h3>Aafiya Khan</h3>
<a href="https://www.linkedin.com/in/aafiya-khan-00a751211/">Product Designer</a>
<h3>Anupam Samanta</h3>
<a href="https://www.linkedin.com/in/anupam96786/">Software Engineer</a>
<!--h1>Openings</h1>
<div class="tab">
  <button class="tablinks" onclick="openTab(event, 'Interns')">Interns</button>
  <button class="tablinks" onclick="openTab(event, 'Employees')">Employees</button>
  <button class="tablinks" onclick="openTab(event, 'Consultants')">Consultants</button>
</div>
<div id="Interns" class="tabcontent">
  <h3>Interns</h3>
  <p>Interns</p>
</div>
<div id="Employees" class="tabcontent">
  <h3>Employees</h3>
  <p>Employees</p>
</div>
<div id="Consultants" class="tabcontent">
  <h3>Consultants</h3>
  <p>Consultants</p>
</div>
<h1>Hiring Process</h1>
<h1>FAQs</h1>
<h1>Past Hiring Stats and Feedback</h1-->
