import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { SYNC_DB_NAME } from '../../sw/local_storage_config';
@Injectable({
  providedIn: 'root',
})
export class NewsletterService {
  public static backendURL = environment.baseURL + 'newsletter';
  constructor(private http: HttpClient) {}
  add(data) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post(NewsletterService.backendURL, [data], {
      headers: headers,
    });
  }
  async addOffline(data) {
    if ('serviceWorker' in navigator) {
      let registration = await navigator.serviceWorker.ready;
      // @ts-ignore
      return registration.sync.register('send-newsletter_subs');
    } else {
      throw new Error('NO_SW');
    }
  }
}
