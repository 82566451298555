<div class="home">
  <div (click)="closeOverlay()" #overlay class="overlay">
    <div (click)="$event.stopPropagation()" class="overlay-wrapper">
      <div id="overlay-spinner">
        <div>
          <div class="loader"></div>
        </div>
      </div>
      <div
        *ngIf="!loadIframe && !loadJoinPartyUI"
        #signupForm
        id="auth-ui-container"
      ></div>
      <div id="extra-links-iframe" *ngIf="loadIframe">
        <iframe
          frameborder="0"
          height="100%"
          width="100%"
          [src]="urlSafe"
          onload="document.getElementById('extra-links-iframe').style.zIndex='7'"
        ></iframe>
      </div>
      <div class="join-us-container" *ngIf="loadJoinPartyUI">
        <powerbank-join-us
          *ngIf="loadJoinPartyUI"
          [adSource]="adSource"
          [source]="joinPartyInitSource"
          [(isSubscribed)]="isSubscribed"
          [(subscribedUser)]="subscribedUser"
          [(hasBooked)]="hasBooked"
        >
        </powerbank-join-us>
      </div>
      <div class="close-overlay-btn-container">
        <button (click)="closeOverlay()" title="Close" class="">&times;</button>
      </div>
    </div>
  </div>
  <div
    class="sticky sticky-top-offer flex-align-center flex-justify-space-between nav-container"
  >
    <a class="no-text-decoration" routerLink="/">
      <div class="logo-text flex flex-align-center">
        INSTANTP <img src="assets/images/logo.png" alt="" /> ST
      </div>
    </a>
    <div>
      <span *ngIf="hasBooked">
        <span class="display-desktop-only">
          Additional <strong>10% off</strong> when your friends book a bank!
          Invite Now!
        </span>
        <span class="display-mobile-only">
          <strong>Invite</strong>
        </span>
      </span>
      <span *ngIf="!hasBooked">
        <span class="display-desktop-only">
          <strong>Half Off</strong> on the retail price
        </span>
        <span class="display-mobile-only">
          <strong>50% off</strong>
        </span>
      </span>
      <button
        (click)="openJoinUI('HOME_TOP_STICKY_BANNER')"
        class="landing-page-btn landing-page-btn-small"
      >
        <span *ngIf="hasBooked">10% More</span>
        <span *ngIf="!hasBooked">Book Now</span>
        &rarr;
      </button>
    </div>
    <div
      (click)="openNavbar()"
      class="navbar-burger"
      [class.is-active]="isNavOpen"
    >
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>

  <div
    class="main"
    [class.sticky]="stickyMain"
    [class.opaq]="opaq"
    [class.hid]="!opaq"
  >
    <h1 class="tag-line tag-line-mobile">Power Bank</h1>
    <div
      style="
        background: url('assets/images/landing_bg.png');
        background-repeat: no-repeat;
      "
      class="brand-container flex-column-wide"
    >
      <div class="slideshow">
        <div class="slides-container"></div>
      </div>
      <div class="brand">
        <div class="get-started">
          <h2 class="tag-line">
            <strong>Power Bank</strong>
          </h2>
          <h5>
            <span *ngIf="hasBooked">
              Additional <strong>10% Off</strong> when your friends book a
              bank!</span
            >
            <span *ngIf="!hasBooked"
              ><strong>50% Off</strong> on retail price</span
            >
          </h5>
          <div class="flex flex-justify-center-mobile">
            <button
              (click)="openJoinUI('HOME_MAIN_BUTTON')"
              class="landing-page-btn"
            >
              <span *ngIf="hasBooked">Invite Now</span>
              <span *ngIf="!hasBooked">Half Off</span>
            </button>
          </div>
          <ng-container [ngSwitch]="adSource">
            <app-powerbank-tagline-speed
              *ngSwitchCase="'79f4fda6-1bc5-4058-ad2e-e2d4aeb382e5'"
            >
            </app-powerbank-tagline-speed>
            <powerbank-taglines-default
              *ngSwitchDefault
            ></powerbank-taglines-default>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div
    class="container has-theme-gradient flex flex-column-mobile flex-justify-center flex-align-center testimonials"
  >
    <div
      class="testimonial flex flex-row background-white margin flex-align-center"
    >
      <div class="media-round is_64x64 margin-right">
        <figure class="is_64x64">
          <img class="" src="assets/images/testimonial_1.png" alt="" />
        </figure>
      </div>
      <div class="media-content">
        <p>
          <strong>Some Person</strong>&nbsp;
          <small>&#64;microsoft</small>
          <br />
          InstantPost Powerbank is the answer to all your power problems!
        </p>
      </div>
    </div>
    <div
      class="testimonial flex flex-row background-white margin flex-align-center"
    >
      <div class="media-round is_64x64 margin-right">
        <figure class="is_64x64">
          <img class="" src="assets/images/testimonial_1.png" alt="" />
        </figure>
      </div>
      <div class="media-content">
        <p>
          <strong>Some Person</strong>&nbsp;
          <small>&#64;microsoft</small>
          <br />
          InstantPost Powerbank is the answer to all your power problems!
        </p>
      </div>
    </div>
  </div>
  <div class="container features">
    <ng-container [ngSwitch]="adSource">
      <powerbank-feature-power
        *ngSwitchCase="'e5dbb22f-eda0-4cdf-9042-ceaec8888bb9'"
        (initJoinUI)="openJoinUI($event)"
      >
      </powerbank-feature-power>
      <powerbank-feature-speed
        *ngSwitchCase="'79f4fda6-1bc5-4058-ad2e-e2d4aeb382e5'"
      ></powerbank-feature-speed>
      <powerbank-feature-default
        *ngSwitchDefault
        (initJoinUI)="openJoinUI($event)"
      ></powerbank-feature-default>
    </ng-container>
  </div>
</div>
