<h1>About Us</h1>
<p>InstantPost is a technology startup based out of Lucknow, Uttar Pradesh, India with branch office in Bangalore, Karnataka, India.</p>
<h3>InstantPost was incorporated in 2015</h3>
<p>Since incorporation we have worked with customers like The Himalaya Drug Company, Seashell Plastics, Jay Engineering, Biopest Management to name a few.</p>
<p>We have served all of our customers for multidisciplinary requirements like software design, electronics design, mechanical design and 3D Printing and many others.</p>
<h3>Recognitions</h3>
<ul>
    <li><strong>Top 30</strong> in Swadeshi Microprocessor Challenge 2020 organised by CDAC and Meity, GoI</li>
    <li><strong>Winner</strong> in Grand Challenge 2021 for Power Bank organised by CDAC, Meity, GoI and Govt of Uttar Pradesh</li>
    <li><strong>Top 5</strong> in Grand Challenge 2021 for 3D Printing organised by CMET, Pune and Meity, GoI</li>
    <li><strong>Top 5</strong> in Innovation Challenge 2021 organised by NIC and Meity, GoI</li>
    <li><strong>Finalist</strong> in IDeathon organised by Forge Forward, Hardware Accelerator</li>
    <li>Presented AutoMagic at <strong>Product Hunt Tear Down</strong> in Jan 2020 at Bangalore</li>
</ul>
<h3>Company Identification Number</h3>
<p>U64204UP2015PTC073626</p>
<h3>GST ID Uttar Pradesh</h3>
<p>09AADCI9354D1ZG</p>
<h3>GST ID Karnataka</h3>
<p>29AADCI9354D1ZE</p>
