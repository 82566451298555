<h1>Contact Us</h1>
<h2>INSTANTPOST PRINTERS AND SCANNERS PRIVATE LIMITED</h2>
<h3>Registered Office Address</h3>
<p>
  307, Crystal Link Apartments, Amar Shaheed Path, Sector – O, Mansarovar Yojna,
  Lucknow – 226012.
</p>
<h3>Branch Office Addresses</h3>
<p>
  15, Jyoti Plaza, Near Singar Nagar Metro Station, Alambagh, Lucknow - 226005
</p>
<p>196, First Floor, Arakere, Bangalore - 560076.</p>
<h3>Mobile</h3>
<p>+91 – 9916374600</p>
<h3>Email</h3>
<p>akhil.mohan&#64;instantpost.in</p>
