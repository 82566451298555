
export class EventLog {
    time: string;
    type: string;
    component: string;
    location: string;
    user: string;
    action: string;
    adsource: string;
    constructor(adsource: string, type: string, component: string, location: string, user: string, action: string = "open") {
        this.time = new Date().toISOString();
        this.type = type;
        this.component = component;
        this.location = location;
        this.user = user;
        this.action = action;
        this.adsource = adsource;
    }
}