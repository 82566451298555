<div class="home">
    <div *ngIf="getRoot()!='/'" class="nav-container sticky">
        <div class="logo-container">
            <div class="logo-img">
            </div>
            <a class="no-text-decoration" routerLink="/">
                <div class="logo-text  flex flex-align-center">INSTANTP <img src="assets/icons/icon-72x72.png" alt=""> ST
                </div>
            </a>
        </div>
        <!--div class="extras">
            <span class="extra-item">
            </span>
            <div (click)="openNavbar()" class="navbar-burger" [class.is-active]="isNavOpen">
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div-->
    </div>
    <!--div class="nav-menu" [class.is-active]="isNavOpen">
        <a (click)="openLink('https://automagicretail.com/')" href="#" class="nav-item">Option 1</a>
        <a routerLink="/usbc" class="nav-item no-text-decoration" (click)="isNavOpen=!isNavOpen">Discover USB 4.0
            Cable</a>
        <a href="#" class="nav-item no-text-decoration">Option 2</a>
    </div-->


    <home-powerbank [(isNavOpen)]="isNavOpen" *ngIf="getRoot()=='/'" [adSource]="adSource"></home-powerbank>
    <div class="container" [class.index-router]="getRoot()!='/'">
        <router-outlet>
        </router-outlet>
    </div>
    <div class="footer">
        <ul class="flex flex-row">
            <li><a routerLink="/about">About</a></li>
            <li><a routerLink="/team">Team</a></li>
            <li><a routerLink="/investors">Investors</a></li>
            <li><a routerLink="/contact">Contact</a></li>
            <li><a routerLink="/terms">Terms</a></li>
            <li><a routerLink="/privacy">Privacy</a></li>
        </ul>
    </div>
    <div *ngIf="showConsent" class="sticky sticky-bottom data-consent text-center">We record user data and events to
        provide the best user
        experience. <a routerLink="privacy">Learn more</a>&nbsp;
        <button class="landing-page-btn landing-page-btn-small display-inline"
            (click)="declineConsent()">Decline</button>
        &nbsp;
        <button class="landing-page-btn landing-page-btn-small display-inline" (click)="acceptConsent()">Allow</button>
    </div>
</div>
