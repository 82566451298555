import { Component, OnInit, ViewChild, ElementRef, Directive, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl, } from '@angular/platform-browser';
import { NewsletterService } from "../shared/newsletter.service"
declare const ipapi: any;

@Component({
  selector: 'home-powerbank',
  templateUrl: './home-powerbank.component.html',
  styleUrls: ['./home-powerbank.component.css', "../index/index.component.css"]
})

export class HomePowerbankComponent implements OnInit {
  @ViewChild('overlay', { static: false })
  public overlay: ElementRef;
  @Input() isNavOpen: Boolean;
  @Output() isNavOpenChange = new EventEmitter<Boolean>();
  public isSubscribed = false;
  public sub = false;
  public hasBooked = false;
  public joinPartyInitSource = "";
  public subscribedUser = "";
  public loadJoinPartyUI: Boolean = false;
  public loadIframe: Boolean = false;
  @Input() adSource: string;
  public iframeAddress: string = "";
  public stickyMain: Boolean = true;
  public opaq: Boolean = true;
  urlSafe: SafeResourceUrl;

  constructor(
    private router: Router,
    private newsletterService: NewsletterService,
    public sanitizer: DomSanitizer,
    // private socketService: SocketService,
    public activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.isSubscribed = JSON.parse(localStorage.getItem("subscribed"));
    this.subscribedUser = localStorage.getItem("subscribed_user");
    console.log(this.adSource)
    if (!this.subscribedUser || !this.subscribedUser.length) {
      this.isSubscribed = false;
      localStorage.setItem("subscribed", "0");
      localStorage.setItem("subscribed_user", null);
    }
    this.hasBooked = JSON.parse(localStorage.getItem("booked"));
  }
  ngAfterViewInit() {
    this.handleScroll();
  }
  openNavbar() {
    this.isNavOpen = !this.isNavOpen;
    this.isNavOpenChange.emit(this.isNavOpen);
  }
  newSubscription() {
    localStorage.setItem("subscribed", "0");
  }
  closeOverlay() {
    this.overlay.nativeElement.style.display = "none";
    this.loadIframe = false;
    this.loadJoinPartyUI = false;
  }
  openLink(link) {
    console.log(link)
    this.overlay.nativeElement.style.display = "flex";
    let a = this.sanitizer.bypassSecurityTrustResourceUrl(link);
    console.log(a);
    this.urlSafe = a;
    // this.iframeAddress = 
    this.loadIframe = true;
  }
  enableOverlay() {
    this.overlay.nativeElement.style.display = "flex";
  }
  openJoinUI(source) {
    console.log(source);
    this.joinPartyInitSource = source;
    this.enableOverlay();
    this.loadJoinPartyUI = true;
  }
  // openSignupForm() {
  //   // console.log(this.signupForm);
  //   this.enableOverlay();
  //   const callback = data => {
  //     console.log(data);
  //     if ("user" in data) {
  //       localStorage.setItem("signup", "1");
  //       localStorage.setItem("subscribed_user", data.user.data.email);
  //       localStorage.setItem("subscribed", "1");
  //       this.hasSignedup = true;
  //       this.requestMessage = this.hasSignedupMessage;
  //     }
  //     this.closeOverlay();
  //   }
  //   document.getElementById('auth-ui-container').style.zIndex = '-1';
  //   let authUI = new ipapi.loader.authUI("auth-ui-container", callback);
  //   authUI.loadSignup();
  //   let frame = document.querySelector("#auth-ui-container iframe");
  //   frame.setAttribute("onload", "document.getElementById('auth-ui-container').style.zIndex='7';");
  //   frame.setAttribute("frameBorder", "none");
  //   // console.log(frame);
  // }
  goToLogin() {
    this.router.navigate(['login']);
  }
  handleScroll() {
    const scrollable: HTMLElement = document.querySelector(".content");
    let currentItem: number = 0;
    const allItems = document.querySelectorAll(".tag-desc-item");
    const lastItem: number = allItems.length - 1;
    let handler = (event) => {
      let currentScrollTop: number = scrollable.scrollTop;
      const scrollFraction: number = (scrollable.scrollTop / (scrollable.scrollHeight - window.innerHeight)) * (lastItem);
      const calculatedIndex: number = Math.ceil(scrollFraction * lastItem);
      console.log(lastItem, calculatedIndex);
      if (calculatedIndex <= lastItem) {
        this.stickyMain = true;
        this.opaq = true;
      }
      else {
        this.opaq = false;
        // this.stickyMain = false;
      }
      const index = Math.min(lastItem, calculatedIndex);
      for (let i = index + 1; i <= lastItem; i++)allItems[i].classList.remove("active");
      for (let i = 0; i <= index; i++)allItems[i].classList.add("active");
      // allItems[currentItem].classList.toggle("active");

      // allItems[index].classList.toggle("active");
      // currentItem = index;
      const main: HTMLElement = document.querySelector(".main");
      // const pos = main.offsetTop - scrollable.scrollTop;
      // const h = window.innerHeight;
      // const opS = 0.3;
      // if (pos < h * opS) {
      //   main.style.opacity = String(pos / ((h * opS) * 1));
      // } else main.style.opacity = "1";
      // console.log(index);
    }
    scrollable.addEventListener("scroll", handler);
  }

}
